import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'

export const colors = {
  whiteSmoke: '#F6F6F6',
  hergaBlue: '#191f52',
  grey: '#a0a0a0',
}

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
}

export const space = {
  px: '1px',
  0: '0',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      @import url('https://rsms.me/inter/inter.css');

      * {
        box-sizing: border-box;
      }

      body {
        font-family: 'Inter var', sans-serif;
        -webkit-font-smoothing: antialiased;
      }

      html,
      body,
      #___gatsby,
      #gatsby-focus-wrapper {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }

      #root {
        overflow: auto;
      }

      a {
        color: white;
      }

      [data-inviewport='scale-in'] {
        transition: 2s;
        transform: scale(0.1);
      }
      [data-inviewport='scale-in'].is-inViewport {
        transform: scale(1);
      }

      [data-inviewport='fade-in-left'] {
        transition: 1.2s;
        transform: translateX(-200px);
        opacity: 0;
      }
      [data-inviewport='fade-in-left'].is-inViewport {
        display: block;
        transform: translateX(0);
        opacity: 1;
      }

      [data-inviewport='fade-in-right'] {
        transition: 1.2s;
        transform: translateX(200px);
        opacity: 0;
      }
      [data-inviewport='fade-in-right'].is-inViewport {
        transform: translateX(0);
        opacity: 1;
      }

      [data-inviewport='fade-rotate'] {
        transition: 2s;
        opacity: 0;
      }
      [data-inviewport='fade-rotate'].is-inViewport {
        transform: rotate(180deg);
        opacity: 1;
      }
    `}
  />
)

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 ${space[6]};
`
export const MainContent = styled.main`
  margin-top: ${space[20]};
  margin-bottom: ${space[10]};

  @media (max-width: ${breakpoints.l}px) {
    margin-top: ${space[5]};
    margin-bottom: ${space[2.5]};
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};
  grid-auto-flow: row dense;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : null};

  & > :nth-of-type(4n - 1) {
    grid-column-start: 2;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : '2.5rem')};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const AlternatingTwoThirdsGrid = styled.div`
  display: grid;
  grid-template-columns: 66% 1fr;
  grid-auto-flow: row dense;
  gap: ${({ gap }) => (gap ? gap : '2.5rem')};

  & > :nth-child(4n - 1) {
    grid-column-start: 2;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const Button = styled.button`
  color: ${({ color }) => (color ? color : 'white')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '0px')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'black'};
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 12px 20px;
  font-size: ${fontSizes.lg};
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  white-space: normal;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #f6f6f6;
    color: #717171;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
`
