import React from "react"

import { GlobalStyle } from "../utils/styles"

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
)

export default Layout
